import React from 'react'

import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'
import { GroupAndServiceData } from '@/components/common/data-list'
import { servicesList } from '@/constants/servicesList'

export default function Company(): JSX.Element {
  return (
    <>
      <Head
        title="サービス一覧 | 株式会社Relicホールディングス"
        url="service"
      />
      <PageLayout
        title="サービス一覧"
        subTitle="SERVICES"
        breadcrumbs="/SERVICES"
        breadcrumbsPath="/service"
      >
        <ul>
          {servicesList.map((company) => (
            <GroupAndServiceData
              title={company.title}
              url={company.url}
              isTargetBlank={company.isTargetBlank}
            />
          ))}
        </ul>
      </PageLayout>
    </>
  )
}
