import { GroupAndServiceDataProps } from '@/components/common/data-list'

export const servicesList: GroupAndServiceDataProps[] = [
  {
    title: 'イノベーションマネジメント・プラットフォーム「Throttle」',
    url: 'https://relic.co.jp/services/throttle/',
    isTargetBlank: true
  },
  {
    title:
      'SaaS型クラウドファンディングプラットフォーム「CROWDFUNDING NETWORK Powered by ENjiNE」',
    url: 'https://relic.co.jp/services/enjine/',
    isTargetBlank: true
  },
  {
    title: '新規事業/イノベーション専門メディア「Battery」',
    url: 'https://relic.co.jp/battery/',
    isTargetBlank: true
  },
  {
    title:
      'クラウドファンディング・ソーシャルレンディング専門メディア「Fintenna」',
    url: 'https://fintenna.jp/',
    isTargetBlank: true
  },
  {
    title:
      '不動産投資型クラウドファンディング「CROWDFUNDING NETWORK Powered by ENjiNE」',
    url: 'https://relic.co.jp/services/enjine/real_estate/',
    isTargetBlank: true
  }
]
